import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { MediumTitleMobile, BodyText } from '../Global';

const Wrapper = styled.article`
  color: inherit;
  height: 100%;
  position: relative;
  z-index: 2;
  padding: 3.188rem 0;
  text-align: center;

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
  }

  &:hover {
    content: '';
    width: 100%;
    height: 100%;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }

  a {
    display: block;
    z-index: 2;
    position: relative;
  }

  p {
    max-width: 208px;
    width: 100%;
    margin: auto;
  }

  .gatsby-image-wrapper {
    max-width: 85px;
    width: 85px;
    height: 80px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
`;

function CategoryCard({
  title, description, featuredImage
}) {
  return (
    <Wrapper>
      <header>
        {featuredImage && (
          <GatsbyImage alt={title} image={getImage(featuredImage)} />
        )}
        <MediumTitleMobile mt="18px">
          {title}
        </MediumTitleMobile>
        <BodyText>
          {description}
        </BodyText>
      </header>
    </Wrapper>
  );
}

CategoryCard.defaultProps = {
  featuredImage: {},
};

CategoryCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  featuredImage: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number,
      base64: PropTypes.string,
      sizes: PropTypes.string,
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcSetWebp: PropTypes.string,
      srcWebp: PropTypes.string,
    }),
  }),
};

export default CategoryCard;
