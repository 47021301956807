/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import PropTypes from 'prop-types';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// Components
import Layout from '../../components/layout';
import Container from '../../components/Container';
import SEO from '../../components/seo';
import HelpHero from '../../components/Help/HelpHero';
import CategoryCard from '../../components/Help/CategoryCard';
import { HeroHeading, BodyText } from '../../components/Global';
import { Grid, GridItem } from '../../components/Grid';
import Autocomplete from '../../components/Autocomplete';
// Style
import theme from '../../styles/theme';
// Utils
import urlHelper from '../../utils/url';
import searchHelper from '../../utils/searchHelper';


const CategorySection = styled.div`
  padding: 63px 0;
  margin-bottom: 130px;
  @media(max-width: 767px){
    padding: 17px 0;
    margin-bottom: 127px;
  }
  @media(min-width: 768px) and (max-width: 989px) {
    padding: 12px 0;
    margin-bottom: 106px;
  }
`;

const CategoryWrapper = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  max-width: 947px;
  margin: 0 auto;
  
  a {
    height: 100%;
  }
`;


const HelpPage = ({ location, data }) => (
  <Layout location={location} useDarkBackgroundWithScroll>
    <SEO
      title="Help Center | Workast | Get Answers to FAQ's and Learn How to Make the Most of Workast"
      description="Learn how to use all the functionalities and capabilities of Workast and get answers to frequently asked questions with our Help Desk."
    />
    <HelpHero>
      <HeroHeading>
        Learn Workast
      </HeroHeading>
      <BodyText mb="29px">
        See how Workast can work for you with step by step guides, best practice, tips and more.
      </BodyText>
      <Autocomplete
        placeholder="Search for help on specific topics"
        onItemClick={searchHelper.onItemClick}
        onSelectItem={searchHelper.onSelectItem}
        onSubmit={searchHelper.onSubmit}
      />
    </HelpHero>
    <CategorySection>
      <Container>
        <CategoryWrapper>
          <Grid gridGap="1px" minCol="300px" bgColor={theme.background.gray}>
            {data.allContentfulHelpCategory.edges.map(({ node }) => (
              <GridItem key={node.categoryId}>
                <Link to={urlHelper.getHelpCategory(node.slug)}>
                  <CategoryCard
                    title={node.categoryTitle}
                    description={node.categoryDescription}
                    featuredImage={node.categoryImage}
                  />
                </Link>
              </GridItem>
            ))}
          </Grid>
        </CategoryWrapper>
      </Container>
    </CategorySection>
  </Layout>
);

/*------------------------------------------------*/
/* PROP TYPES
/*------------------------------------------------*/
HelpPage.propTypes = {
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
  data: PropTypes.shape({
    allContentfulHelpCategory: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            categoryTitle: PropTypes.string,
            categoryDescription: PropTypes.string,
            categoryId: PropTypes.string,
            categoryImage: PropTypes.shape({
              fluid: PropTypes.shape({
                aspectRatio: PropTypes.number,
                base64: PropTypes.string,
                sizes: PropTypes.string,
                src: PropTypes.string,
                srcSet: PropTypes.string,
                srcSetWebp: PropTypes.string,
                srcWebp: PropTypes.string,
              }),
            }),
          }),
        })
      ),
    }).isRequired,
  }).isRequired,
};

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default HelpPage;

export const pageQuery = graphql`
  query HelpPageQuery{
    allContentfulHelpCategory(sort: {order: ASC, fields: order}) {
      edges {
        node {
          categoryTitle
          categoryDescription
          categoryId
          categoryImage {
            gatsbyImageData(width:85)
          }
          slug
        }
      }
    }
  }
`;
